import Container from 'react-bootstrap/Container';
import Favicon from './svg/Favicon';
import '../css/Loading.scss';

export default function Loading({ msg }) {
  return (
    <Container fluid className="vh-100 d-flex align-items-center justify-content-center loading">
      <div className="text-center">
        <Favicon width={100} height={100} />
        {msg &&
          <p className="mt-4 mb-0" dangerouslySetInnerHTML={{ __html: msg }}></p>
        }
      </div>
    </Container>
  );
}