import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Card, Form, Button } from 'react-bootstrap';
import { getCall } from '../utils/lib';
import Wrapper from '../components/Wrapper';
import Header from '../components/Header';
import Footer from '../components/Footer';
import PhoneNumber from '../components/svg/PhoneNumber';
import { terms } from '../utils/terms';

import Error from '../components/Error';
import Loading from '../components/Loading';

import { FaArrowRight } from 'react-icons/fa';

export default function Payment() {
  const { appName, paymentId, tokenId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState('');
  const [validated, setValidated] = useState(false);

  const [conditions, setConditions] = useState('');

  const [isAccepted1, setIsAccepted1] = useState(false);
  const [isAccepted2, setIsAccepted2] = useState(false);

  // Use effect
  useEffect(() => {
    if (!isLoaded) {
      setIsLoaded(true);
      
      getCall(appName, paymentId, tokenId, false)
        .then(res => {
          // Res
          const { paymentUrl, token } = res.data;

          // Se le condizioni sono già state accettate
          if (token.conditionsAccepted) {
            window.location.href = paymentUrl;
          } else {
            // Se le condizioni non sono già state accettate
            setConditions(token.conditions);
            setIsLoading(false);
          }
        })
        .catch(err => {
          // Gli oggetti di risposta
          const { response, message } = err;

          setIsError(true);
          setError(response.data.data.message ? response.data.data.message : message);
          
          //console.log(err);
        });
    }
  }, [isLoaded, appName, paymentId, tokenId]);

  // Form submit
  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setValidated(true);

    if (!e.currentTarget.checkValidity()) {
      return;
    }
    
    setIsLoading(true);

    getCall(appName, paymentId, tokenId, true)
      .then(res => {
        // Res
        const { paymentUrl, token } = res.data;

        // Se le condizioni sono già state accettate
        if (token.conditionsAccepted) {
          window.location.href = paymentUrl;
        } else {
          // Si è verificato un errore
          setIsError(true);
        }
      })
      .catch(err => {
        setIsError(true);
        setError(err.message);
        
        console.log(err);
      });
  };
  
  if (isError) {
    return <Error msg={error} />;
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Wrapper>
      <Header />
      <Container as="main" fluid className="my-auto">
        <Card className="mx-auto width-720">
          <Card.Body className="py-4 py-md-5 px-3 px-md-5">
            <div className="mb-4">
              <h1 className="ff-montserrat fw-bold fs-4">Accetta Termini e Condizioni</h1>
              <p className="mb-0">Per poter proseguire con il pagamento è necessario accettare i termini e le condizioni di fornitura dei servizi.</p>
            </div>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <div className="my-4">
                <Form.Check
                  type="switch"
                  id="switch1"
                  label="Accetto le condizioni di fornitura dei servizi"
                  onClick={() => setIsAccepted1(!isAccepted1)}
                  required
                />
                <div className="textarea-condizioni mt-2">
                  <div className="textarea overflow-auto rounded" dangerouslySetInnerHTML={{ __html: conditions}} />
                </div>
              </div>
              <div className="mt-4 mb-5">
                <Form.Check
                  type="switch"
                  id="switch2"
                  label="Accetto l’informativa privacy"
                  onClick={() => setIsAccepted2(!isAccepted2)}
                  required
                />
                <div className="textarea-condizioni mt-2">
                  <div className="textarea overflow-auto rounded" dangerouslySetInnerHTML={{ __html: terms}} />
                </div>
              </div>
              <div className="text-center">
                <Button type="submit" className="btn-save" disabled={isAccepted1 && isAccepted2 ? false : true}>
                  Salva e prosegui
                  <FaArrowRight />
                </Button>
              </div>
            </Form>
            <div className="mt-4 text-center">
              <p>Hai bisogno di assistenza?</p>
              <a href="tel:800122777" className="d-inline-block">
                <PhoneNumber />
              </a>
            </div>
          </Card.Body>
        </Card>
      </Container>
      <Footer />
    </Wrapper>
  );
}