import Wrapper from '../components/Wrapper';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Error from '../components/Error';

export default function Ko() {
  return (
    <Wrapper>
      <Header />
      <Error
        title="Si è verificato un errore"
        msg="Gentile cliente, si è verificato un <strong>errore</strong> temporaneo del sistema.<br />La invitiamo a riprovare più tardi e per qualsiasi informazione aggiuntiva o assistenza contatti pure il <strong>numero verde</strong>."
        phoneNumber={true}
      />
      <Footer />
    </Wrapper>
  );
}