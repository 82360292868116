import Container from 'react-bootstrap/Container';
import Wrapper from '../components/Wrapper';
import Header from '../components/Header';
import Footer from '../components/Footer';

import { BsFillCheckCircleFill } from 'react-icons/bs';

export default function Ko() {
  return (
    <Wrapper>
      <Header />
      <Container as="main" fluid className="vh-100 d-flex align-items-center justify-content-center">
        <div className="text-center width-720">
          <BsFillCheckCircleFill className="text-success" style={{ width: '5rem', height: '5rem' }} />
          <h1 className="ff-montserrat fw-bold mt-5">Operazione completata!</h1>
          <p className="mt-5 mb-0">La Sua procedura di acquisto è andata a buon fine.</p>
        </div>
      </Container>
      <Footer />
    </Wrapper>
  );
}