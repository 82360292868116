import { Routes, Route } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import Error from './components/Error';
import Payment from './pages/Payment';
import Ok from './pages/Ok';
import Ko from './pages/Ko';

// GTM
TagManager.initialize({
  gtmId: 'GTM-W4Q22R47'
});

const errTitle = 'Ops, si è verificato un errore!';
const errMsg = 'La pagina che stavi cercando non esiste oppure è stata rimossa.';

export default function App() {
  return (
    <Routes>
      <Route path="/" element={<Error title={errTitle} msg={errMsg} />} />
      <Route path="/app/:appName/id/:paymentId/token/:tokenId" element={<Payment />} />
      <Route path="/ok" element={<Ok />} />
      <Route path="/ko" element={<Ko />} />
      <Route path="*" element={<Error title={errTitle} msg={errMsg} />} />
    </Routes>
  );
};