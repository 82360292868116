import Container from 'react-bootstrap/Container';
import PhoneNumber from '../components/svg/PhoneNumber';
import { BsExclamationTriangle } from 'react-icons/bs';

export default function Error({ title, msg, phoneNumber }) {
  return (
    <Container as="main" fluid className="vh-100 d-flex align-items-center justify-content-center">
      <div className="text-center width-720">
        <BsExclamationTriangle className="text-danger" style={{ width: '5rem', height: '5rem' }} />
        {title &&
          <h1 className="ff-montserrat fw-bold mt-5" dangerouslySetInnerHTML={{ __html: title }} />
        }
        {msg &&
          <p className="mt-5 mb-0" dangerouslySetInnerHTML={{ __html: msg }} />
        }
        {phoneNumber &&
          <div className="mt-5">
            <a href="tel:800122777" className="d-inline-block">
              <PhoneNumber width={175} height={60} />
            </a>
          </div>
        }
      </div>
    </Container>
  );
}